import "regenerator-runtime/runtime";
const feather = require('feather-icons');
feather.replace();
require('./bootstrap');

$(window).ready(function() {
    const DELAY_ALERT_DURATION = 4000;
    const SLIDEUP_ALERT_DURATION = 200;

    $(".full-page-loader-wrapper").fadeOut("slow");
    $(".alert").delay(DELAY_ALERT_DURATION).slideUp(SLIDEUP_ALERT_DURATION, function() {
        $(this).alert('close');
    });
});
